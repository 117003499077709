.about__landing__container {
    width: 100%;
    height: 40vh;
    background-image: url('../imgs/woman\ lifting\ weight\ both\ hands.jpg');
    background-position: center bottom;
    background-size: cover;
}

.about__landing__container div {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__landing__container div h1 {
    color: white;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: .5rem;
    text-transform: uppercase;
}

.about__desc__1 {
    padding: 3rem;
    background-color: var(--rich-black);
    color: white;
}

.about__desc__1 h1{
    margin-bottom: 2rem;
}

.about__desc__1 p{
    margin-bottom: 2rem;
}

.about__desc__2 {
    padding:3rem 1rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.about__desc__2 div {
    text-align: center;
    flex: 1 0 300px;
}

.about__desc__2 div h2 {
    margin-bottom: 1rem;
    color: var(--office-green);
}

.about__desc__2 div p {
    margin-bottom: 1rem;
    color: var(--dark-moss-green);
}

.about__desc__3 {
    padding: 1rem;
    background-color: var(--light-green);
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.about__desc__3 div {
    padding: 1rem;
    flex: 1 0 380px;
    background-color: var(--isabelline);
}

.about__desc__3 div h2 {
    color: var(--dark-moss-green);
}

.about__desc__4 {
    padding: 3rem;
    background-color: #f5f5f5;
    text-align: center;
}

.about__desc__4 p {
    margin-bottom: 2rem;
}

@media screen and (max-width: 456px) {
    

    .about__landing__container {
        position: relative;
        top: 3rem;
    }
    .about__desc__1, .about__desc__2{
        padding:3rem 1rem;
    }
    .about__desc__3 div {
        padding: 1rem;
        flex: 1 0 290px;
        background-color: var(--isabelline);
    }
    .about__desc__4 {
        padding: 3rem 1rem;
    }

}








