.landing__main__container {
    position: relative;
    width: 100%;
    height: 70vh;
    background-image: url('../imgs/back\ neck\ pain\ photo.jpg');
    background-size: cover;
    background-position: center;
    z-index: 1;
}

.landing__main__container::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.landing__main__container div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 3rem;
    z-index: 3;
}

.landing__main__container__div h1 {
    margin-bottom: 2rem; 
}

@media screen and (max-width: 456px) {
    .landing__main__container div {
        top: 3rem;
        padding: 1rem;
    }
}













