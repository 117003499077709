.footer{
    background-color: var(--rich-black);
    color: #fff;
    padding: 2rem;
}

.footer__all__items__container {
    display: flex;
    justify-content: space-between;
  
}

.social__container {
    width: 300px;
    padding: 1rem 1rem 2rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
   
}

.social__container div{
    display: flex;
    align-items: center;
}

.social__container h3{
    margin-right: 1rem;
}

.f__icon {
    margin-right: 1rem;
}

.f__icon, .i__icon {
    width: 35px;
    height: 35px;
    color: #fff;
}

.footer__logo {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--mantis);
    display: flex;
    align-items: center;
}

.footer__logo img {
    width: 55px;
    height: 55px;
    margin-right: 1rem;
    border-radius: 50px;
    border: solid 3px var(--mantis);
}

.footer__contact__info {
    
    margin: 1rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer__contact__info p a{
    color: var(--ut-orange);
    text-decoration: none;
}

.footer__links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 1rem 0;
}

.footer__date__priv {
    border-top: solid #fff 1px;
    padding-top: 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer__date__priv a{
    color: var(--ut-orange);
    text-decoration: none;
}


details:hover {
    cursor: pointer;
}

@media screen and (max-width:789px) {
    .footer__all__items__container {
        flex-direction: column;
    }
    .footer__date__priv {
        flex-direction: column;
        
    }
    .footer__date__priv a{
        
        border-bottom: none;
    }

    .social__container {
        align-items: end;
        justify-content: flex-start;
        margin-left: 0;
        padding-left: 0;
    }

}


@media screen and (max-width: 456px) {
    .footer__logo {
        font-size: 1rem;
    }

}






























