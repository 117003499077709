nav {
    padding: 1rem 2rem;
    background-color: var(--isabelline);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo__p__container {
    display: flex;
    align-items: center;
}

.nav__logo__p__container img{
    width: 55px;
    height: 55px;
    margin-right: 1rem;
    border-radius: 100px;
    border: solid 3px var(--mantis);
}

.nav__logo__p__container p {
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--office-green);
}

.nav__container__ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav__container__ul ul {
    display: flex;
    justify-content: space-between;
}

.nav__li__all{
    list-style-type: none;
    margin: 0 1rem 0 0;
}

.nav__li__all a {
    text-decoration: none;
    color: var(--rich-black);
}

.nav__li__all a:hover {
    color: var(--office-green);
} 

.btn__menu {
    display: none;
}

nav.show__menu {

}

.hide__menu {

}

@media screen and (max-width: 987px) {
    nav {
        position: fixed;
        width: 100%;
        z-index: 999;
    }
    .nav__container__ul{
        position: fixed;
        height: 0;
        width: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #101910;
        z-index: 998;
        transition: .3s;
    }
    .nav__container__ul.active {
        height: 100%;
      }

    .nav__container__ul ul {

        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .nav__container__ul ul li a {
        color: white;
        padding: 1rem;
        display: flex;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: .5rem;
        text-transform: uppercase;
    }

    .btn__cta__no__border.btn__cta__spam {
        text-decoration: none;
        text-align: center;
        width: 220px;
    }

    .btn__menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 35px;
        height: 25px;
        cursor: pointer;
        z-index: 998;
    }
    
    .btn__menu span {
        width: 100%;
        height: 4px;
        background-color: var(--mantis);
    }

}

@media screen and (max-width: 456px) {
    nav {
        padding: .5rem 1rem;
    }
    .nav__logo__p__container p {
        font-weight: 500;
        font-size: 1rem;
    }

    .btn__menu {
        width: 25px;
        height: 20px;
    }
}





