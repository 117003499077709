.services__container__landing {
    position: relative;
    top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.services__container__landing h1,
.services__container__landing p {
    margin-bottom: 2rem;
    line-height: 2rem;
    color: var(--office-green);
}

.btn__cta__no__border.services__landing__btn {
    display: flex;
    margin-bottom: 3rem;
    width: 200px;
    justify-content: center;
}

.services__container__1 {
    display: flex;
    justify-content: space-between;
    background-color: #E7F7DE;
}

.services__item__1__1 {
    padding: 3rem 0 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.services__container__1 h2 {
    padding: 0 3rem;
    margin-bottom: 1rem;
    color: var(--dark-moss-green);
} 

.services__container__1 p {
    padding: 0 3rem;
    margin-bottom: 2rem;
}

.services__container__1 span {
    margin-left: 3rem;

}


.services__container__1__bg__img {
    width: 500px;
    height: 100%;
    background-image: url('../imgs/car-accident-rescu.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.services__container__2 {
    padding:3rem 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: #DBF3CE;
}

.services__container__2 div {
    background-color: #E7F7DE;
    padding: 1rem;
    flex: 1 0 320px;
}

.services__container__2 h2 {
    border-bottom: solid 3px var(--office-green);
    color: var(--rich-black);
    margin-bottom: 1rem;
}

.services__container__1.bg__color__3 {
    background-color: #E7F7DE;
}

.services__container__1__bg__img.img__3 {
    background-image: url('../imgs/person\ side\ hombro\ massage.jpg');
}

.services__container__4 {
    /* background-color: #C4EBAD; */
    padding:5rem 3rem;
    text-align: center;
    line-height: 2rem;
}

.services__container__4 h2 {
    color: var(--dark-moss-green);
    margin-bottom: 2rem; 
}

.services__container__4 p {
    margin-bottom: 3rem;
}

.btn__cta__sc4 {
    background-color: var(--dark-moss-green);
    color: white;
    padding: .5rem 1.5rem;
    text-decoration: none;
    font-weight: 600;
    
}


@media screen and (max-width: 1200px) {
    .services__container__1 {
        flex-direction: column;
    }
    .services__container__1__bg__img {
        height: 400px;
        width: 100%;
    }

}

@media screen and (max-width: 456px) {
    .services__container__landing {
        position: relative;
        padding: 3rem 1.5rem;
    }
    .services__container__1 h2{
        padding: 1rem;
    }
    .services__container__1 p {
        padding: 1rem;
    }
    .services__container__1 span{
        padding: 0;
        text-align: center;
    }

    .services__container__4 {
        padding: 5rem 1rem;
    }
}









