
.contact__landing {
    padding: 3rem 3rem 5rem;
    margin-bottom: 3rem;
    text-align: center;
}
.contact__landing h1{
    margin:5rem 2rem 2rem;
    font-size: 2rem;
    color: var(--dark-moss-green);
}
.contact__landing p{
    margin-bottom: 3rem;
    color: var(--office-green);
    line-height: 2rem;
}

.contact__container__1 {
    width: 100%;
    height: 100vh;
    background-image: url('../imgs/patiente\ front\ office.jpg');
    background-position: center;
    background-size: cover;


}

.contact__container__form{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.contact__container__form h2 {
    margin-bottom: 2rem;
    font-size: 3rem;
    text-transform: uppercase;
}

.contact__container__form p {
    text-transform: uppercase;
    margin-bottom: 2rem;
    
}

.contact__container__form input {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    width: 500px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;

}
.contact__container__form textarea {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    width: 500px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;

}

.contact__container__form button {
    padding: .7rem 2rem;
    margin-bottom: 1rem;
    width: 500px;
    font-size: 1.3rem;
    text-transform: uppercase;
    background-color: var(--ut-orange);
    color: white;
    border: none;
    transition: .3s;
}

.contact__container__form button:hover {
    background-color: #c46200;
}

.contact__calendly {
    padding: 5rem;
    background-color: var(--light-green);
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.make__appt {
    width: 100%;
    height: 700px;
    background-image: url('../imgs/calendly-img.JPG');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.make__appt div {
    width: 100%;
    height: 100%;
    border-radius: 0 40px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 5px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.make__appt div h3 {
    color: white;
    font-size: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.make__appt div a {
    padding: .7rem 2rem;
    margin-bottom: 1rem;
    width: 500px;
    font-size: 1.3rem;
    text-transform: uppercase;
    background-color: var(--ut-orange);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
    transition: .3s;
}

.make__appt div a:hover {
    background-color: chocolate;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 987px) {

    .make__appt div {
        border-radius: 0 ;
    }

    .contact__calendly {
        padding: 1rem;
    }

    .make__appt div h3 {
        font-size: 2rem;
        text-align: center;
    }

    .make__appt div a {
        padding: .7rem 2rem;
        width: 300px;
        font-size: 1rem;
    }
}

@media screen and (max-width: 456px) {
    .contact__landing {
        padding: 3rem 1rem 4rem;

    }

    .contact__container__form input {
        padding: 1rem 2rem;
        margin-bottom: 1rem;
        width: 300px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
    
    }
    .contact__container__form textarea {
        width: 300px;
    }
    
    .contact__container__form button {
        width: 300px;
    }


}