@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --light-green: #ACE894;
    --mantis: #82D173;
    --office-green: #337619;
    --dark-moss-green: #255412;
    --rich-black: #0D1321;
    --steel-blue: #3F88C5;
    --ut-orange: #FF8811;
    --mustard: #FFDD4A;
    --isabelline: #EDECE9;
}

.btn__cta__border {
    text-decoration: none;
    padding: .5rem 1.5rem;
    margin: 2rem 0;
    border: solid 2px var(--office-green);
    color: var(--office-green);
    font-weight: 700;
    transition: .3s;
}

.btn__cta__border:hover {
    background-color: var(--office-green);
    color: white;
}
.btn__cta__border__mantis {
    text-decoration: none;
    padding: .5rem 1.5rem;
    margin: 2rem 0;
    border: solid 2px var(--mantis);
    color: var(--mantis);
    font-weight: 700;
    transition: .3s;
}

.btn__cta__border__mantis:hover {
    background-color: var(--mantis);
    color: white;
}

.btn__cta__no__border {
    text-decoration: none;
    padding: .5rem 1.5rem;
    background-color: var(--mantis);
    color: #000;
    font-weight: 500;
    transition: .3s;
}

.btn__cta__no__border:hover {
    padding: .5rem 1.5rem;
    background-color: var(--office-green);
    color: #fff;
}

.btn__cta__border.bg__darker{
    background-color: var(--office-green);
    color: #fff;
    border: none;
    margin-bottom: 3rem;
}
.btn__cta__border.bg__darker:hover{
    background-color: var(--dark-moss-green);
    color: #fff;
}

.clean__text {
    text-decoration: none;
}








