.home__container__1 {
    padding: 3rem;
    background-color: var(--rich-black);
}

.home__container__1 h2 {
    color: var(--light-green);
    margin: 1rem 1rem 1rem 0;
}

.home__container__1 p {
    margin-bottom: 3rem;
    color: #fff;
}

.home__container__2 {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.home__container__2 div{
    padding: 1rem;
    flex: 1 0 400px;
}

.home__container__2 div h2{
    margin-bottom: 1rem;
    color: var(--office-green);
}

.home__container__2 div p{
    margin-bottom: 2rem;
}

.home__container__3 {
    padding: 3rem;
    margin-bottom: 3rem;
    text-align: center;
}

.home__container__3 h2{
    margin-bottom: 3rem;
    color: var(--office-green);
}

@media screen and (max-width: 456px) {
    .home__container__1 {
        padding: 1rem;
    }

    .home__container__2 {
        padding: .3rem;
    }

}





