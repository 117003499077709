.bg__light__green{
    background-color: var(--light-green);
}

.bg__mantis__green {
    background-color: var(--mantis);
}

.bg__moss__green {
    background-color: #6cb95d;
}

.testimonial__main__container {
    padding: 1rem;
    background-color: var(--isabelline);
}

.testimonial__main__container h2{
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    color: var(--office-green);
}

.testimonial__div__container {
    display: flex;
    justify-content: center;
}

.testimonial__div {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.testimonial__card__container__all {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;

}

.testimonial__card__container__all h3{
    margin-bottom: 1rem;
}

.testimonial__card__container__all p{
    text-align: center;
}
  
.testimonial__names {
    color: var(--ut-orange);
}

.testimonial__img__all {
    width: 64px;
    border-radius: 100px;
    margin-top: 2rem;
}

.star__rating {
    font-size: 1.7rem;
    color: var(--ut-orange);
}

